/* eslint-disable */
import { Article } from "../../types/type";
import { Link } from "react-router-dom";

interface ArticleListProps {
  articles: Article[];
  onDeleteArticle: (id: string) => void;
}

const ArticleList: React.FC<ArticleListProps> = ({
  articles,
  onDeleteArticle,
}) => {
  return (
    <div>
      {articles.map((article) => (
        <div key={article.id}>
          <h2>{article.title}</h2>
          <p>{article.content}</p>
          <Link
            className="add-column-btn"
            to={`/admin/article/modify/${article.id}`}
          >
            수정하기
          </Link>
          <button onClick={() => onDeleteArticle(article.id!)}>Delete</button>
        </div>
      ))}
    </div>
  );
};

export default ArticleList;
