/* eslint-disable */
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { UX_EVALUATION } from "../types/enum";
import { getApiClient } from "../api/client.api";
import {
  Article,
  Category,
  ImageSet,
  KeywordData,
  Product,
} from "../types/type";
import { getKeywords } from "../api/keywords.api";
import { getArticleFromId, updateArticle } from "../api/articles.api";

const ModifyArticle = () => {
  const articleId = window.location.pathname.split("/admin/article/modify/")[1];
  const apiClient = getApiClient();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [keywordId, setKeywordId] = useState<string>();
  const [keywordKorName, setKeywordKorName] = useState<string>();
  const [images, setImages] = useState<ImageSet[]>([
    { imageUrl: "", description: "" },
  ]); // 이미지 세트 추가

  const [author, setAuthor] = useState("");
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorInsta, setAuthorInsta] = useState("");
  const [authorLinkedIn, setAuthorLinkedIn] = useState("");
  const [articleLinks, setArticleLinks] = useState<string[]>([""]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(
    "",
  );
  const [product, setProduct] = useState<string>("");
  const [productImage, setProductImage] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [availableKeywords, setAvailableKeywords] = useState<
    KeywordData[] | undefined
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined,
  );

  const [uxEvaluationTag, setUxEvaluationTag] = useState<string>();

  useEffect(() => {
    getArticleFromId(apiClient, articleId).then((r) => {
      setTitle(r.data?.title);
      setContent(r.data?.content);
      setKeywordKorName(r.data?.keywordKorName);
      setKeywordId(r.data?.keywordId);
      setUxEvaluationTag(r.data?.uxEvaluation);
      setImages(r.data?.images);
      setAuthor(r.data?.author);
      setAuthorEmail(r.data.authorEmail);
      setAuthorInsta(r.data.authorInsta);
      setAuthorLinkedIn(r.data.authorLinkedIn);
      setArticleLinks(r.data?.articleLink);
      setSelectedProduct(r.data?.productId);
      setProduct(r.data?.product);
      setProductImage(r.data?.productImage);
    });
  }, []);

  useEffect(() => {
    const fetchCategoriesAndKeywords = async () => {
      try {
        const [catResponse, keywordResponse, proResponse] = await Promise.all([
          axios.get("/api/categories"),
          axios.get("/api/keywords"),
          axios.get("/api/products"),
        ]);
        setCategories(catResponse.data);
        setAvailableKeywords(keywordResponse.data);
        setProducts(proResponse.data);

        const originCategoryId = keywordResponse.data.filter(
          (el) => el.id === keywordId,
        )[0]?.categoryId;
        setSelectedCategory(originCategoryId);
      } catch (error) {
        console.error("Failed to fetch categories and keywords:", error);
      }
    };

    fetchCategoriesAndKeywords();
  }, [keywordId]);

  useEffect(() => {
    getKeywords(apiClient).then((res) => {
      setAvailableKeywords(
        res.data?.filter((el) => el.categoryId === selectedCategory),
      );
    });
  }, [selectedCategory]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };
  const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProduct(e.target.value);
    const selectedProductElement = products.filter(
      (el) => el.id === e.target.value,
    )[0];
    setProduct(selectedProductElement.name!);
    setProductImage(selectedProductElement.imageUrl!);
  };
  const handleKeywordChange = (selectedOptions: any) => {
    setKeywordId(selectedOptions.value.id);
    setKeywordKorName(selectedOptions.value.koreaName);
    setContent(selectedOptions.value.desc);
    setArticleLinks(selectedOptions.value.studyLink);
  };

  const handleUxEvaluationTagChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUxEvaluationTag(e.target.value);
  };

  const handleAddArticleLink = () => {
    setArticleLinks([...articleLinks, ""]);
  };

  const handleRemoveArticleLink = (index: number) => {
    setArticleLinks(articleLinks.filter((_, i) => i !== index));
  };

  const handleArticleLinkChange = (index: number, value: string) => {
    const newLinks = [...articleLinks];
    newLinks[index] = value;
    setArticleLinks(newLinks);
  };

  const handleAddImageSet = () => {
    setImages([...images, { imageUrl: "", description: "" }]);
  };

  const handleRemoveImageSet = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleImageChange = (index: number, value: string) => {
    const newImages = [...images];
    newImages[index].imageUrl = value;
    setImages(newImages);
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const newImages = [...images];
    newImages[index].description = value;
    setImages(newImages);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newArticle: Article = {
      id: articleId,
      title,
      generateTime: new Date().toISOString(),
      content,
      keywordKorName: keywordKorName,
      keywordId: keywordId,
      uxEvaluation: uxEvaluationTag,
      images: images,
      author,
      articleLink: articleLinks,
      product,
      productId: selectedProduct,
      productImage,
      authorEmail,
      authorInsta,
      authorLinkedIn,
    };
    updateArticle(apiClient, articleId, newArticle).then((r) => {
      location.replace("/admin");
    });
  };

  return (
    <div className="article-form">
      <div className="title">글 쓰기</div>
      <form onSubmit={handleSubmit}>
        <label>
          제목
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목을 입력하세요."
            required
          />
        </label>
        <label>
          Product:
          <select
            value={selectedProduct}
            onChange={handleProductChange}
            required
          >
            <option value="">Select Product</option>
            {products.map((pro) => (
              <option key={pro.id} value={pro.id}>
                {pro.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Category:
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            required
          >
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.koreaName}
              </option>
            ))}
          </select>
        </label>

        <label>
          Keywords:
          <Select
            isMulti={false}
            name="keywords"
            options={availableKeywords?.map((keyword) => ({
              label: keyword.koreaName,
              value: keyword,
            }))}
            onChange={handleKeywordChange}
            value={
              availableKeywords
                ?.map((keyword) => ({
                  label: keyword.koreaName,
                  value: keyword,
                }))
                .find((option) => option.value.id === keywordId) || null
            }
            required
          />
        </label>

        <label>
          UX Tag:
          <select
            value={uxEvaluationTag}
            onChange={handleUxEvaluationTagChange}
            required
          >
            <option value="">Select Tag</option>
            {Object.entries(UX_EVALUATION).map(([key, value]) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </label>

        <label>
          글 작성자
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="글 작성자를 입력하세요."
            required
          />
        </label>

        <label>
          작성자 Email
          <input
            type="email"
            value={authorEmail}
            onChange={(e) => setAuthorEmail(e.target.value)}
            placeholder="Email을 입력하세요."
          />
        </label>

        <label>
          작성자 Instagram
          <input
            type="url"
            value={authorInsta}
            onChange={(e) => setAuthorInsta(e.target.value)}
            placeholder="Instagram 링크를 입력하세요."
          />
        </label>

        <label>
          작성자 LinkedIn
          <input
            type="url"
            value={authorLinkedIn}
            onChange={(e) => setAuthorLinkedIn(e.target.value)}
            placeholder="LinkedIn 링크를 입력하세요."
          />
        </label>

        {articleLinks.map((link, index) => (
          <div key={index} className="article-link-input">
            <input
              type="url"
              value={link}
              onChange={(e) => handleArticleLinkChange(index, e.target.value)}
              placeholder="참고 링크"
            />
            {articleLinks.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveArticleLink(index)}
              >
                X
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddArticleLink}>
          + 참고 링크 추가
        </button>
        <label>
          본문
          <textarea
            className="content-box"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="본문을 입력하세요."
            required
          />
        </label>

        {/* 이미지와 설명 세트 */}
        {images.map((imgSet, index) => (
          <div key={index} className="image-set">
            <label>
              이미지 {index + 1}
              <input
                type="url"
                value={imgSet.imageUrl}
                onChange={(e) => handleImageChange(index, e.target.value)}
                placeholder="이미지 URL"
                required
              />
            </label>
            <label>
              설명
              <input
                type="text"
                value={imgSet.description}
                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                placeholder="이미지 설명 (선택 사항)"
              />
            </label>
            {images.length > 1 && (
              <button type="button" onClick={() => handleRemoveImageSet(index)}>
                X
              </button>
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddImageSet}>
          + Add Image
        </button>
        <button type="submit">아티클 수정하기</button>
      </form>
    </div>
  );
};

export default ModifyArticle;
