export default function ShareBtn(props: { copyUrl: string }) {
  const handleCopyClipBoard = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(props.copyUrl);
      alert("클립보드에 아티클 링크가 복사되었습니다.");
    } catch (err) {
      alert("복사를 실패하였습니다. 다시 시도해주세요.");
      console.log(err);
    }
  };

  return (
    <div className="share-btn-box" onClick={handleCopyClipBoard}>
      <img src={`${process.env.PUBLIC_URL}` + "/assets/icons/FiLink.svg"} />
      <div className="share-text">아티클 공유하기</div>
    </div>
  );
}
